import React, { Component } from 'react';
import LoadingDots  from "../components/LoadingDots";
import sales from '../lib/sales-service';
import { Button, Form } from 'react-bootstrap';
import { withAuth } from "../lib/AuthProvider";
import Cal from "../components/date";
import CalNew from "../components/dateNew";
import CalOld from "../components/dateOld";
import moment from 'moment';
import $ from 'jquery';
import { GrUpdate } from "react-icons/gr";
import { IconContext } from "react-icons";
import sftp from '../lib/sftp-service';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { downloadExcel } from "react-export-table-to-excel";

class Player extends Component {
    state = {
        data: {
            data: {
                id:'',
                name: '',
                start: '',
            },
        },
        kpi: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        // kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        
        kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
        
        kpiold0: [{CODIGO:'',DESCRIPCION:'',FECHA: 'sin datos',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        history: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        id: [{_id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        hour: '',
        hora: '00',
        min: '00',
        isLoading: true,
        status: "loading",
        timer: '',
        autoplay: false,
        realtime: '',
        calendarDate: true,
        ppto: [{CENTRO: '',COSTE: '', DAY: '', FECHA:'', MARGEN: '', MONTH: '', VENTAS:'', YEAR:''},],
        }

    componentDidMount() {
        setTimeout(() => {
            let hora = moment().format('HH')
            let min = moment().format('mm')
            this.handledbComplete()
            this.handlesftpComplete()

            // this.handleSales()
            // this.setState({
            //             hora,
            //             min
            //             })
        }, 1000)
       
        this.setState({
            timer: setInterval(() => {
                // let hora = moment().format('HH')
                let min = moment().format('mm:ss')
                switch (min) {
                    case '00:20' :
                      console.log('00!');
                      // this.handleSales()
                        this.handledbComplete()
                        // this.setState({
                        // hora,
                        // min
                        // })
                      break;
                    case '10:20':
                      console.log('10!');
                      // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '20:20':
                      console.log('20!');
                      // this.handleSales()
                     this.handledbComplete()
                    //  this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '30:20':
                      console.log('30!');
                      // this.handleSales()
                     this.handledbComplete()
                    //  this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '40:20':
                    console.log('40!');
                    // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    // hora,
                    // min
                    // })
                    break;
                    case '50:20':
                    console.log('50!');
                    // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    // hora,
                    // min
                    // })
                    break;
                    default:
                    
                }
            }, 1000)
        })
    }
      
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    convertArray = (kpi) => {
        kpi.sort((a,b) => {
            if (a.TIPO < b.TIPO){
                return -1;
            } return 1;
        })
       
        this.arraymove(kpi,0,75) //COMP
        this.arraymove(kpi,76,88) //E-COMMERCE
        this.arraymove(kpi,78,87) //I100
        this.arraymove(kpi,78,87) //I101
        this.arraymove(kpi,76,87) //I000
        this.arraymove(kpi,76,87) //I010
        this.arraymove(kpi,76,80) //NO COMP

        let ind = kpi.map((e,i) => e.CODIGO === 'T210' ?  i: '').filter(String)
        
        this.arraymove(kpi,ind,62) //T210
        // this.arraymove(kpi,78,79) //NO COMP

        // this.arraymove(kpi,0,75) //COMP
        // this.arraymove(kpi,73,0) //D001
        // this.arraymove(kpi,74,1) //D101
        // this.arraymove(kpi,76,86) //E-COMMERCE
        // this.arraymove(kpi,79,84) //I101
        // this.arraymove(kpi,78,83) //I100
        // this.arraymove(kpi,77,82) //I010
        // this.arraymove(kpi,76,81) //I000
        // arraymove(kpi,74,76) //NO COMP
    }
   
    filterShop = (kpi) => { //ELIMINAR TIENDAS

    let indexes = kpi.map((e,i) =>  e.CODIGO === 'T005' 
                                || e.CODIGO === 'T027'
                                || e.CODIGO === 'I302'
                                || e.CODIGO === 'I001'
                                || e.CODIGO === 'T035'
                                || e.CODIGO === 'T041'
                                || e.CODIGO === 'I032'
                                || e.CODIGO === 'T999'
                                || e.CODIGO === 'F006'
                                || e.CODIGO === 'F004'
                                || e.CODIGO === 'F001' 
                                || e.CODIGO === 'T609' 
                                || e.CODIGO === 'T301'  ?  i: '').filter(String)
                            
    for(let i = indexes.length -1; i >= 0; i--){
        kpi.splice(indexes[i],1)
    }

    let indexes2  = kpi.map((e,i) =>  e.CODIGO === 'T409'
                                || e.CODIGO === 'T029'
                                || e.CODIGO === 'D001'
                                || e.CODIGO === 'D101'
                                || e.CODIGO === null ? i: '').filter(String)

    for(let i = indexes2.length -1; i >= 0; i--){
        kpi.splice(indexes2[i],1)
        }
    
    let indx3 = kpi.map((e,i) => e.CODIGO === 'I000' ?  i: '').filter(String)
        kpi[indx3].DESCRIPCION = 'Venta bruta facturada'

    }

    unionEco = (kpi) => { //UNIR ECORGANIC
      
       let date = kpi[0].FECHA
    if (date > '2022-09-30') {
        console.log('mayor '+ kpi.length)
        // let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        // kpi[i].CODIGO = 'T601'
        // console.log(kpi[i])
        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T401' || e.CODIGO === 'T402' || e.CODIGO === 'T403' || e.CODIGO === 'T404' || e.CODIGO === 'T405'|| e.CODIGO === 'T406' || e.CODIGO === 'T407' || e.CODIGO === 'T408' || e.CODIGO === 'T409'|| e.CODIGO === 'T410' || e.CODIGO === 'T411' || e.CODIGO === 'T412'?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
       } else {
        console.log('menor ' + kpi.length)

        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T601' || e.CODIGO === 'T602' || e.CODIGO === 'T603' || e.CODIGO === 'T604' || e.CODIGO === 'T605' || e.CODIGO === 'T606' ?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
        let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T601'
         i = kpi.map((e,i) => e.CODIGO === 'T402' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T602'
         i = kpi.map((e,i) => e.CODIGO === 'T403' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T603'
         i = kpi.map((e,i) => e.CODIGO === 'T404' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T604'
         i = kpi.map((e,i) => e.CODIGO === 'T405' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T605'
         i = kpi.map((e,i) => e.CODIGO === 'T406' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T606'

        let oldIndexes2 = kpi.map((e,i) =>  e.CODIGO === 'T607' || e.CODIGO === 'T210'|| e.CODIGO === 'T609'|| e.CODIGO === 'T610' || e.CODIGO === 'T611' || e.CODIGO === 'T612' ?  i: '').filter(String)
        for(let o = oldIndexes2.length -1; o >= 0; o--){
            kpi.splice(oldIndexes2[o],1)
        }
        
        
        let o = kpi.map((e,i) => e.CODIGO === 'T407' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T607'

         o = kpi.map((e,i) => e.CODIGO === 'T408' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T210'

        // o = kpi.map((e,i) => e.CODIGO === 'T409' ?  i: '').filter(String)
        // kpi[o].CODIGO = 'T609'

        o = kpi.map((e,i) => e.CODIGO === 'T410' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T610'

        o = kpi.map((e,i) => e.CODIGO === 'T411' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T611'

        o = kpi.map((e,i) => e.CODIGO === 'T412' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T612'

        }

        

    }

    handleSalesYear = () => {
        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
        this.setState({
            isLoading: false,
        })
        let date = new Date("2023,10"); //Fecha inicial
        for (let i = 1; i < 90; i++){ //Suma de días a grabar
            let dateplus = date.addDays(i)
            sales.onlyreadAll(dateplus)
            .then((history) => {
                this.filterShop(history)
                this.unionEco(history)
                this.convertArray(history)
                this.setState({
                    history,
                    isLoading: true,
                    autoplay: true,
                })
            })
            .then(()=>{// guardar fecha en cada línea
                let indexes = this.state.history.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
                const FECHA =  moment(this.state.history[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {history} = this.state;
                for (let i = 0; i < history.length; i++) {
                    history[i] = {
                        ...history[i],
                        FECHA: FECHA
                    }
                };
                this.setState({
                    history,
                    isLoading: true,
                    autoplay: true,
                })
            })
            .then(()=>{// guardar fecha en cada línea
                let indexes = this.state.history.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
                const FECHA =  moment(this.state.history[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {history} = this.state;
                for (let i = 0; i < history.length; i++) {
                    history[i] = {
                        ...history[i],
                        FECHA: FECHA
                    }
                };
                this.setState({
                    history,
                    isLoading: true,
                    autoplay: true,
                }) 
            })
            .then(()=>{//guardar historico en mongo
                const history = this.state.history
                sales.create(history)
                   this.setState({
                       isLoading: true,
                       autoplay: true,
                   })
                })
            .catch(error => {
                this.setState({
                    status: "error",
                    isLoading: false
                });
            });
        }
    }
    
    handleSales = () => {
        this.setState({
            isLoading: false,
        });
        sales.onlyread ()
        .then((kpi) => {
            //Change Array order and save state
            this.filterShop(kpi)
            this.unionEco(kpi)
            this.convertArray(kpi)
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            //convert Date and add to kpi.FECHA
            let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            const FECHA =  moment(this.state.kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            const kpi = this.state.kpi
            sales.create (kpi)
            const dateHour = new Date()
            const hour = dateHour.getHours()
            this.setState({
                kpi,
                hour,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            })
            this.handleOldSales(kpi[0].FECHA)
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
                });
            });
    }

    handleOldSales = (olDate) => {
        this.setState({
            isLoading: false,
        });
        // let olDate = this.state.kpi[0].FECHA
        sales.onlyreadold(olDate)
        .then((kpiold) => {
            if (kpiold.length > 1) {
                this.setState({
                    kpiold,
                    status: "loaded",
                    isLoading: true,
                })
            } else {
                this.setState({
                    kpiold: this.state.kpiold0,
                    status: "loaded",
                    isLoading: true,
                })
            }
        })
        .then (() => {
            if (this.state.kpiold[0].FECHA !== "sin datos" ) {
                //convert Date and add to kpi.FECHA
                let indexes = this.state.kpiold.map((e,i) => e.FECHA !== null ?  i: '').filter(String)
                const FECHA =  moment(this.state.kpiold[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {kpiold} = this.state;
                for (let i = 0; i < kpiold.length; i++) {
                    kpiold[i] = {
                        ...kpiold[i],
                        FECHA: FECHA
                        }
                }
                this.setState({
                    kpiold,
                    isLoading: true,
                });
            }
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false,
            });
        });
    }

    handledbComplete = () => {
        const date =  moment().format('YYYY-MM-DD')
        this.setState({
            isLoading: false
            })
        sales.read (date) 
        .then((kpi) => {
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            const FECHA =  moment(this.state.kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .then(() => {
            const {kpi} = this.state
            const dateHour = new Date()
            const hour = dateHour.getHours()
            this.setState({
                hour,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            })
            this.handleOldSales(kpi[0].FECHA)
        })
        .then(()=> {
            const {kpi} = this.state
            let hora = moment(kpi[0].updated_at).format("HH")
            let min =  moment(kpi[0].updated_at).format("mm")
            this.setState({
                hora,
                min
            })
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }
    
    handledb = () => {
        this.setState({
            isLoading: false
        });
        sales.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            const FECHA =  moment(this.state.kpi[0].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    // handlesftp = () => {
    //     console.log('update ppto')
    //     this.setState({
    //         isLoading: false,
    //     });
    //     sftp.read ()
    //     .then((ppto) => {
    //         this.setState({
    //             ppto,
    //             isLoading: true,
    //             autoplay: true,
    //         })
    //     })
    //     // .then(() => {
    //     //     const {ppto} = this.state
    //     //     user.create (ppto)
    //     //     this.setState({
    //     //         ppto,
    //     //         status: "loaded",
    //     //         isLoading: true,
    //     //         autoplay: true,
    //     //     });
            
    //     // })
    //     .catch(error => {
    //         this.setState({
    //             status: "error",
    //             isLoading: false
    //         });
    //     });
    // };

    handlesftpComplete = () => {
        const date =  moment().format('YYYY-MM-DD')
        this.setState({
            isLoading: false
            })
        sftp.readmongo (date) 
        .then((ppto) => {
            this.setState({
                ppto,
                isLoading: true,
                autoplay: true,
            })
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }

    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (dateStringCandidateValue === ''){
        } else {
            if (!dateStringCandidateValue) { return null; }
            let mapFormat = format
                    .split("")
                    .reduce(function (a, b, i) { a[b] = i; return a;}, {});
            const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
            const datePart = dateStr2Array.slice(0, 3);
            let datePartFormatted = [
                    +datePart[mapFormat.y],
                    +datePart[mapFormat.m]-1,
                    +datePart[mapFormat.d] ];
            if (dateStr2Array.length > 3) {
                dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
            }
            // test date validity according to given [format]
            const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
            return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
                dateTrial.getMonth() === datePartFormatted[1] &&
                dateTrial.getDate() === datePartFormatted[2]
                    ? dateTrial :
                    null;
        }
    }
 
    logChildValue = (kpi, date, ppto) => {
        console.log('datos calendario')
        let hour = ''
        this.setState({
            kpi,
            ppto,
            hour,
            calendarDate: true,
        })
        this.handleOldSales(date)
        const dateHour = new Date()
        if (moment(dateHour).format('YYYY-MM-DD') === this.state.kpi[0].FECHA) {
             const hour = dateHour.getHours()
             this.setState({hour})
        }
    }

    logChildValueOld = (kpiold) => {
        console.log('datos antiguos')
        let hour = ''
        this.setState({
            kpiold,
            hour,
            calendarDate: false,
        })
        console.log('old ' + this.state.kpiold.length)

    }

    logChildValueNew = (kpi, ppto) => {
        console.log('datos nuevos')
        let hour = ''
        this.setState({
            kpi,
            ppto,
            hour,
            calendarDate: false,
        })
        const dateHour = new Date()
        if (moment(dateHour).format('YYYY-MM-DD') === this.state.kpi[0].FECHA) {
             const hour = dateHour.getHours()
             this.setState({hour})
        }
    }

    arraymove = (arr, fromIndex, toIndex) => {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    sendEmail = () => {
        sales.email(this.state.kpi)
    }
    
    fun = (x) => {
        let ne = this.state.kpi.filter(function(val, i) {
            if (val.CODIGO !== 'COMP' && val.CODIGO !== 'NO COMP' && val.CODIGO !== 'FRANQ') {
                return val.TIPO===x;
                } 
            })
        return ne
    }
   
    toggle = () => {
        console.log('pulsado')
        var lab = $("label");
        var swi = $(".custom-switch");
        if (lab.css("color",'green!important')) {
            lab.css("color",'red!important')
            } else {
                lab.css("color",'green!important')
            }
    }

    formatAsPercentage = (num) => {
        return new Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(num / 100);
    }
  
   
    render () {
        // console.log(this.state.kpiold)
        window.addEventListener("visibilitychange", function () {
            // console.log("Visibility changed");
            if (document.visibilityState === "visible") {
            //   console.log("APP resumed");
              window.location.reload();
            }
          });
        const { logout } = this.props;
     
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
       
        const redHour = {
            // color:'white',
            // background:'orange',
            fontWeight: 'bold',
            borderLeft: '3px solid black',
            borderRight: '3px solid black',
        }
        const redTop = {
            color:'white',
            background:'orange',
            fontWeight: 'bold',
        }
        const greenHour = {
            // color:'white',
            // background:'#4CAF50',
            fontWeight: 'bold',
            borderLeft: '3px solid black',
            borderRight: '3px solid black',
        }
        const greenTop = {
            color:'white',
            background:'#4CAF50',
            fontWeight: 'bold',
        }
        const redOld = {
            // color:'red',
            background:'#f0f3f5'
        }
        const greenOld = {
            // color:'#4CAF50',
            background:'#f0f3f5'
        }
        const green = {
            color:'#4CAF50'
        }
        const red = {
            color:'red'
        }
        const up = {
            background:'#f4de9c',
            borderColor:'#f3ce81'
        }
        const blue = {
            color:'white',
            background: '#2196F3',
            background: '#c95e2c',
            borderColor: '#2c8cf3',
            fontWeight: 'bold',
        }
       

        /*Nav scroll */
        $(function() {
            var header = $(".fixNav");
            $(window).scroll(function() {    
                var scroll = $(window).scrollTop();
            
                if (scroll >= 150) {
                    header.removeClass('fixNav').addClass("delNav");
                } else {
                    header.removeClass("delNav").addClass('fixNav');
                }
            });

            
        });
        
        // console.log(this.state.kpi)

        // console.log(this.state.kpiold)



       
        return (

        <div className="AppPL">
            <div className='fixNav'>
            <div style={{background: '#c95f2c'}}>
            <button style={{background: '#c95f2c'}} className="logout" onClick={logout}><img src="../Images/18404466911582779196-128.png" alt="log" style={{width: '1rem'}}/></button>
            </div>
            <nav className="NavPL">
                <div className="PL">
                <h1>PANEL DE VENTAS</h1>
                <h3>Fecha de ventas:<p> {this.state.kpi[0].FECHA}</p></h3>
                <h3>Fecha comparativa:<p> {this.state.kpiold[0].FECHA}</p></h3>
                </div>
                <Cal parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} onSubmitChild={this.logChildValue} />
                <div className="cal">
                    <h3>SELECCIONA FECHAS A COMPARAR</h3>
                    <div className="cal2">
                    <CalNew parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} onSubmitChild={this.logChildValueNew} />
                    <CalOld parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} date={this.state.kpiold[0].FECHA} onSubmitChild={this.logChildValueOld} />
                    </div>
                    {/* <button onClick={this.handleDownloadExcel}>download excel</button> */}
                </div>
            </nav>
            <div className='hourBar'>
                <h1 className='hora'>HORA DE ACTUALIZACIÓN {this.state.hora}:{this.state.min}</h1>
                {this.state.isLoading === false ?
                
                  <div className="loadup" >
                  <LoadingDots color={'white'}/>   
                  </div>          
                :
                <div>
                <IconContext.Provider value={{ className:"upcon"}}>
                <button style={{background: '#c95f2c'}} className="up"onClick={this.handledbComplete}><GrUpdate/></button>
                </IconContext.Provider>
                </div>
                }
            </div>
            </div>
        <header className="App-header-pl">
            <table className="tableMov">
                <thead className='tHead'>
                    <tr className='trplayerSH'>
                        <th className="descplST">
                            <p>TIENDA</p>
                        </th>
                        <th className='tdContainerS sup'>
                        { this.state.hour === 8 ? <div><h2 className='activeHour'>9H</h2></div> : <div><h2>9H</h2></div>}
                        { this.state.hour === 9 ? <div><h2 className='activeHour'>10H</h2></div> : <div><h2>10H</h2></div>}
                        { this.state.hour === 10 ? <div><h2 className='activeHour'>11H</h2></div> : <div><h2>11H</h2></div>}
                        { this.state.hour === 11 ? <div><h2 className='activeHour'>12H</h2></div> : <div><h2>12H</h2></div>}
                        { this.state.hour === 12 ? <div><h2 className='activeHour'>13H</h2></div> : <div><h2>13H</h2></div>}
                        { this.state.hour === 13 ? <div><h2 className='activeHour'>14H</h2></div> : <div><h2>14H</h2></div>}
                        { this.state.hour === 14 ? <div><h2 className='activeHour'>15H</h2></div> : <div><h2>15H</h2></div>}
                        { this.state.hour === 15 ? <div><h2 className='activeHour'>16H</h2></div> : <div><h2>16H</h2></div>}
                        { this.state.hour === 16 ? <div><h2 className='activeHour'>17H</h2></div> : <div><h2>17H</h2></div>}
                        { this.state.hour === 17 ? <div><h2 className='activeHour'>18H</h2></div> : <div><h2>18H</h2></div>}
                        { this.state.hour === 18 ? <div><h2 className='activeHour'>19H</h2></div> : <div><h2>19H</h2></div>}
                        { this.state.hour === 19 ? <div><h2 className='activeHour'>20H</h2></div> : <div><h2>20H</h2></div>}
                        { this.state.hour === 20 ? <div><h2 className='activeHour'>21H</h2></div> : <div><h2>21H</h2></div>}
                        { this.state.hour === 21 ? <div><h2 className='activeHour'>22H</h2></div> : <div><h2>22H</h2></div>}
                        { this.state.hour === 22 ? <div><h2 className='activeHour'>23H</h2></div> : <div><h2>23H</h2></div>} 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.kpi.map ((kpi, idx) => {
                        return  <tr className='trplayerS' key={kpi.CODIGO} >
                                {/* <th>
                                <Form>
                                <Form.Check 
                                    type="switch"
                                    id="custom-switch"
                                    label={kpi.CODIGO}
                                    isValid="false"
                                    onChange={() => this.toggle()}
                                />
                                </Form>
                                </th> */}
                        
                                <th className="descplS"  >
                                    <h3>{kpi.CODIGO}</h3>
                                    <p>{kpi.DESCRIPCION}</p>
                                    {/* {this.state.ppto.map ((ppto) => {  
                                        return <>
                                            { kpi.FECHA === moment().format("YYYY-MM-DD")  ?
                                                <>
                                                { this.state.hour === 8 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA9/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA9/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 9 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    { kpi.HORA10 === 0 ?
                                                    <ProgressBar animated variant="success" now={kpi.HORA9/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA9/ppto.VENTAS*100)}`}/>
                                                    :
                                                    <ProgressBar animated variant="success" now={kpi.HORA10/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA10/ppto.VENTAS*100)}`}/>
                                                    }
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 10 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    { kpi.HORA11 === 0 ?
                                                    <ProgressBar animated variant="success" now={kpi.HORA10/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA10/ppto.VENTAS*100)}`}/>
                                                    :
                                                    <ProgressBar animated variant="success" now={kpi.HORA11/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA11/ppto.VENTAS*100)}`}/>
                                                    }
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 11 && ppto.CENTRO === kpi.CODIGO?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    { kpi.HORA12 === 0 ?
                                                    <ProgressBar animated variant="success" now={kpi.HORA11/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA11/ppto.VENTAS*100)}`}/>
                                                    :
                                                    <ProgressBar animated variant="success" now={kpi.HORA12/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA12/ppto.VENTAS*100)}`}/>
                                                    }          
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 12 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    { kpi.HORA13 === 0 ?
                                                    <ProgressBar animated variant="success" now={kpi.HORA12/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA12/ppto.VENTAS*100)}`}/>
                                                    :
                                                    <ProgressBar animated variant="success" now={kpi.HORA13/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA13/ppto.VENTAS*100)}`}/>
                                                    }
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 13 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    { kpi.HORA14 === 0 ?
                                                    <ProgressBar animated variant="success" now={kpi.HORA13/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA13/ppto.VENTAS*100)}`}/>
                                                    :
                                                    <ProgressBar animated variant="success" now={kpi.HORA14/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA14/ppto.VENTAS*100)}`}/>
                                                    }
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 14 &&  ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    { kpi.HORA15 === 0 ?
                                                    <ProgressBar animated variant="success" now={kpi.HORA14/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA14/ppto.VENTAS*100)}`}/>
                                                    :
                                                    <ProgressBar animated variant="success" now={kpi.HORA15/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA15/ppto.VENTAS*100)}`}/>
                                                    }
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 15 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA16/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA16/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 16 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA17/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA17/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 17 && ppto.CENTRO === kpi.CODIGO?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA18/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA18/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 18 && ppto.CENTRO === kpi.CODIGO?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA19/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA19/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 19 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA20/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA20/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 20 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA21/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA21/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 21 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA22/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA22/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                { this.state.hour === 22 && ppto.CENTRO === kpi.CODIGO ?
                                                <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={kpi.HORA23/ppto.VENTAS*100} label={`${this.formatAsPercentage(kpi.HORA23/ppto.VENTAS*100)}`}/>
                                                </div>
                                                :
                                                <>
                                                </>
                                                }
                                                </>
                                            :
                                            <>
                                            { kpi.HORA23 >= ppto.VENTAS ?
                                                <>
                                                    { ppto.CENTRO === kpi.CODIGO ?
                                                    <div key={ppto.CENTRO}>
                                                    <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                    <ProgressBar animated variant="success" now={(kpi.HORA23/ppto.VENTAS)*100} label={`${this.formatAsPercentage((kpi.HORA23/ppto.VENTAS)*100)}`}/>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    { ppto.CENTRO === kpi.CODIGO ?
                                                        <div key={ppto.CENTRO}>
                                                            <p>PPTO {formatter.format(ppto.VENTAS)}</p>
                                                            <ProgressBar animated variant="warning" now={(kpi.HORA23/ppto.VENTAS)*100} label={`${this.formatAsPercentage((kpi.HORA23/ppto.VENTAS)*100)}`}/>
                                                        </div>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                </>
                                                }
                                            </>
                                            }
                                        </>     
                                    })}  */}
                                </th>
                            <td className='tdContainerS' >
                            {this.state.kpiold.map ((kpiold ) => {   
                            return <> 
                                { kpi.CODIGO === kpiold.CODIGO ?
                                <>
                                { this.state.hour === 8 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA9 < kpi.HORA9 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA9}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA9}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA9}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA9}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA9)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA9)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA9 < kpi.HORA9  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA9)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA9)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={1}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA9 < kpi.HORA9 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 9 ? //Si Los datos son mayores que los antiguos mostrar en verde (D101,I101,I010)
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA9}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA9}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA9}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA9}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 9 ? //Si Los datos son menores que los antiguos mostrar en rojo (D101,I101,I010)
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA9}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA9}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA9}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA9}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA9 >= Math.max(...this.fun('C').map((i) => i.HORA9)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA9 !== 0 && this.state.hour !== 9  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA9 < kpi.HORA9  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA9 < kpi.HORA9 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 9 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA9)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA9)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 9 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA9)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA9)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA9 < kpi.HORA9  ?
                                                    <>  
                                                    { this.state.hour === 9 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA9)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA9)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 9 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA9)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA9)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 9 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA10 < kpi.HORA10 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA10}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA10}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA10}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA10}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA10)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA10)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA10 < kpi.HORA10  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA10)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA10)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={2}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA10 < kpi.HORA10 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 10 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA10}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA10}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA10}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA10}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 10 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA10}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA10}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA10}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA10}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA10 >= Math.max(...this.fun('C').map((i) => i.HORA10)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA10 !== 0 && this.state.hour !== 10  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA10 < kpi.HORA10  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA10 < kpi.HORA10 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 10 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA10)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA10)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 10 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA10)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA10)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA10 < kpi.HORA10  ?
                                                    <>  
                                                    { this.state.hour === 10 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA10)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA10)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 10 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA10)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA10)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 10 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA11 < kpi.HORA11 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA11}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA11}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA11}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA11}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA11)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA11)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA11 < kpi.HORA11  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA11)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA11)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={3}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA11 < kpi.HORA11 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 11 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA11}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA11}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA11}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA11}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 11 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA11}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA11}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA11}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA11}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA11 >= Math.max(...this.fun('C').map((i) => i.HORA11)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA11 !== 0 && this.state.hour !== 11  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA11 < kpi.HORA11  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA11 < kpi.HORA11 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 11 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA11)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA11)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 11 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA11)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA11)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA11 < kpi.HORA11  ?
                                                    <>  
                                                    { this.state.hour === 11 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA11)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA11)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 11 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA11)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA11)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 11 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA12 < kpi.HORA12 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA12}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA12}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA12}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA12}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA12)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA12)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA12 < kpi.HORA12  ?// HORA ACTUAL
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA12)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA12)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={4}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                            { kpiold.HORA12 < kpi.HORA12 ? //Numero
                                                <>  
                                                    {/* <h3 style={green}>{kpi.HORA12}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA12}</h3> */}
                                                    { this.state.hour === 12 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{kpi.HORA12}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA12}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={green}>{kpi.HORA12}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA12}</h3>
                                                        </>
                                                    }
                                                </>
                                            :
                                                <>
                                                    {/* <h3 style={red}>{kpi.HORA12}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA12}</h3> */}
                                                    { this.state.hour === 12 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{kpi.HORA12}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA12}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={red}>{kpi.HORA12}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA12}</h3>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                            <>
                                            { kpi.HORA12 >= Math.max(...this.fun('C').map((i) => i.HORA12)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA12 !== 0 && this.state.hour !== 12 ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA12 < kpi.HORA12  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA12 < kpi.HORA12 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 12 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA12)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA12)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 12 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA12)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA12)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA12 < kpi.HORA12  ?
                                                    <>  
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA12)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA12)}</h3> */}
                                                    
                                                    { this.state.hour === 12 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA12)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA12)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                    </>
                                                    }
                                                    </>
                                                :
                                                    <>
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA12)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA12)}</h3> */}
                                                    { this.state.hour === 12 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA12)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA12)}</h3>
                                                    </>
                                                    }
                                                    </>
                                                }
                                            </>
                                            }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 12 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA13 < kpi.HORA13 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA13}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA13}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA13}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA13}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA13 < kpi.HORA13  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={5}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA13 < kpi.HORA13 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 13 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA13}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA13}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA13}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA13}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 13 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA13}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA13}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA13}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA13}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA13 >= Math.max(...this.fun('C').map((i) => i.HORA13)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA13 !== 0 && this.state.hour !== 13  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA13 < kpi.HORA13  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA13 < kpi.HORA13 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 13 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 13 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA13 < kpi.HORA13  ?
                                                    <>  
                                                    { this.state.hour === 13 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 13 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 13 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA14 < kpi.HORA14 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA14}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA14}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA14}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA14}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA14)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA14)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA14 < kpi.HORA14  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA14)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA14)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={6}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA14 < kpi.HORA14 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 14 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA14}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA14}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA14}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA14}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 14 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA14}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA14}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA14}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA14}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA14 >= Math.max(...this.fun('C').map((i) => i.HORA14)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA14 !== 0 && this.state.hour !== 14  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA14 < kpi.HORA14  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA14 < kpi.HORA14 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 14 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA14)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA14)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 14 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA14)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA14)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA14 < kpi.HORA14  ?
                                                    <>  
                                                    { this.state.hour === 14 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA14)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA14)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 14 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA14)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA14)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 14 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA15 < kpi.HORA15 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA15}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA15}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA15}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA15}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA15)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA15)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA15 < kpi.HORA15  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA15)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA15)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={7}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA15 < kpi.HORA15 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 15 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA15}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA15}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA15}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA15}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 15 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA15}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA15}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA15}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA15}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA15 >= Math.max(...this.fun('C').map((i) => i.HORA15)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA15 !== 0 && this.state.hour !== 15  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA15 < kpi.HORA15  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA15 < kpi.HORA15 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 15 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA15)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA15)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 15 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA15)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA15)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA15 < kpi.HORA15  ?
                                                    <>  
                                                    { this.state.hour === 15 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA15)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA15)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 15 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA15)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA15)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 15 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA16 < kpi.HORA16 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA16}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA16}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA16}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA16}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA16)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA16)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA16 < kpi.HORA16  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA16)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA16)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={8}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA16 < kpi.HORA16 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 16 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA16}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA16}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA16}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA16}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 16 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA16}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA16}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA16}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA16}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA16 >= Math.max(...this.fun('C').map((i) => i.HORA16)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA16 !== 0 && this.state.hour !== 16  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA16 < kpi.HORA16  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA16 < kpi.HORA16 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 16 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA16)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA16)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 16 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA16)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA16)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA16 < kpi.HORA16  ?
                                                    <>  
                                                    { this.state.hour === 16 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA16)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA16)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 16 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA16)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA16)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 16 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA17 < kpi.HORA17 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA17}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA17}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA17}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA17}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA17)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA17)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA17 < kpi.HORA17  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA17)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA17)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={9}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA17 < kpi.HORA17 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 17 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA17}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA17}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA17}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA17}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 17 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA17}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA17}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA17}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA17}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA17 >= Math.max(...this.fun('C').map((i) => i.HORA17)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA17 !== 0 && this.state.hour !== 17  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA17 < kpi.HORA17  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA17 < kpi.HORA17 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 17 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA17)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA17)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 17 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA17)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA17)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA17 < kpi.HORA17  ?
                                                    <>  
                                                    { this.state.hour === 17 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA17)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA17)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 17 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA17)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA17)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 17 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA18 < kpi.HORA18 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA18}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA18}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA18}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA18}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA18)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA18)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA18 < kpi.HORA18  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA18)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA18)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={10}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA18 < kpi.HORA18 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 18 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA18}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA18}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA18}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA18}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 18 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA18}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA18}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA18}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA18}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA18 >= Math.max(...this.fun('C').map((i) => i.HORA18)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA18 !== 0 && this.state.hour !== 18  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA18 < kpi.HORA18  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA18 < kpi.HORA18 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 18 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA18)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA18)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 18 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA18)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA18)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA18 < kpi.HORA18  ?
                                                    <>  
                                                    { this.state.hour === 18 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA18)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA18)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 18 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA18)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA18)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 18 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA19 < kpi.HORA19 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA19}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA19}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA19}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA19}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA19)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA19)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA19 < kpi.HORA19  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA19)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA19)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={11}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA19 < kpi.HORA19 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 19 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA19}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA19}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA19}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA19}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 19 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA19}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA19}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA19}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA19}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA19 >= Math.max(...this.fun('C').map((i) => i.HORA19)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA19 !== 0 && this.state.hour !== 19  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA19 < kpi.HORA19  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA19 < kpi.HORA19 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 19 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA19)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA19)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 19 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA19)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA19)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA19 < kpi.HORA19  ?
                                                    <>  
                                                    { this.state.hour === 19 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA19)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA19)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 19 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA19)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA19)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 19 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA20 < kpi.HORA20 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA20}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA20}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA20}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA20}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA20)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA20)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA20 < kpi.HORA20  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA20)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA20)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={12}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA20 < kpi.HORA20 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 20 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA20}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA20}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA20}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA20}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 20 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA20}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA20}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA20}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA20}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA20 >= Math.max(...this.fun('C').map((i) => i.HORA20)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA20 !== 0 && this.state.hour !== 20  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA20 < kpi.HORA20  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA20 < kpi.HORA20 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 20 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA20)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA20)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 20 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA20)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA20)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA20 < kpi.HORA20  ?
                                                    <>  
                                                    { this.state.hour === 20 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA20)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA20)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 20 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA20)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA20)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 20 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA21 < kpi.HORA21 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA21}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA21}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA21}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA21}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA21)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA21)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA21 < kpi.HORA21  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA21)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA21)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={13}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA21 < kpi.HORA21 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 21 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA21}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA21}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA21}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA21}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 21 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA21}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA21}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA21}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA21}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA21 >= Math.max(...this.fun('C').map((i) => i.HORA21)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA21 !== 0 && this.state.hour !== 21  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA21 < kpi.HORA21  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA21 < kpi.HORA21 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 21 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA21)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA21)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 21 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA21)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA21)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA21 < kpi.HORA21  ?
                                                    <>  
                                                    { this.state.hour === 21 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA21)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA21)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 21 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA21)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA21)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 21 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA22 < kpi.HORA22 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA22}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA22}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA22}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA22}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA22)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA22)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA22 < kpi.HORA22  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA22)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA22)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={14}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA22 < kpi.HORA22 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 22 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA22}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA22}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA22}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA22}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 22 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA22}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA22}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA22}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA22}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA22 >= Math.max(...this.fun('C').map((i) => i.HORA22)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA22 !== 0 && this.state.hour !== 22  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA22 < kpi.HORA22  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA22 < kpi.HORA22 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 22 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA22)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA22)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 22 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA22)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA22)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA22 < kpi.HORA22  ?
                                                    <>  
                                                    { this.state.hour === 22 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA22)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA22)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 22 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA22)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA22)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                { this.state.hour === 22 ?
                                    <div key={kpiold.CODIGO}>
                                    { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                        <>
                                            { kpiold.HORA23 < kpi.HORA23 ?
                                                <>  
                                                    <h3 style={green}>{kpi.HORA23}</h3>
                                                    <h3 style={redOld}>{kpiold.HORA23}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{kpi.HORA23}</h3>
                                                    <h3 style={greenOld}>{kpiold.HORA23}</h3>
                                                </>
                                            }
                                        </>
                                    :
                                        <>
                                            {kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                            <>
                                                <h3 style={blue}>{formatter.format(kpi.HORA23)}</h3>
                                                <h3 style={blue}>{formatter.format(kpiold.HORA23)}</h3>
                                            </>
                                            :
                                            <>
                                            { kpiold.HORA23 < kpi.HORA23  ?
                                                <>  
                                                    <h3 style={green}>{formatter.format(kpi.HORA23)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                </>
                                            :
                                                <>
                                                    <h3 style={red}>{formatter.format(kpi.HORA23)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                </>
                                            }
                                            </>
                                            }
                                        </>
                                    }
                                    </div>
                                :
                                    <div key={15}>
                                        { kpi.CODIGO === 'D101' || kpi.CODIGO === 'I101' || kpi.CODIGO === 'I010' ?
                                            <>
                                                { kpiold.HORA23 < kpi.HORA23 ? //Numero
                                                    <>  
                                                        {/* <h3 style={green}>{kpi.HORA13}</h3>
                                                        <h3 style={redOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 23 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={greenTop}>{kpi.HORA23}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA23}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={green}>{kpi.HORA23}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA23}</h3>
                                                            </>
                                                        }
                                                    </>
                                                :
                                                    <>
                                                        {/* <h3 style={red}>{kpi.HORA13}</h3>
                                                        <h3 style={greenOld}>{kpiold.HORA13}</h3> */}
                                                        { this.state.hour === 23 ? //TEST HORA ANTERIOR
                                                            <>
                                                            <h3 style={redTop}>{kpi.HORA23}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA23}</h3>
                                                            </>
                                                            :
                                                            <>
                                                            <h3 style={red}>{kpi.HORA23}</h3>
                                                            <h3 style={redOld}>{kpiold.HORA23}</h3>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        :
                                            <>
                                            { kpi.HORA23 >= Math.max(...this.fun('C').map((i) => i.HORA23)) && kpi.CODIGO !== 'COMP' && kpi.TIPO === 'C' && kpi.HORA23 !== 0 && this.state.hour !== 23  ? //max Value!!!!!!!!!!
                                            <>
                                                { kpiold.HORA23 < kpi.HORA23  ?
                                                    <>  
                                                        <h3 style={up}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={redOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                    </>
                                                :
                                                    <>
                                                        <h3 style={up}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                { kpi.CODIGO === 'COMP' || kpi.CODIGO === 'NO COMP' || kpi.CODIGO === 'FRANQ.' || kpi.CODIGO === 'E-COMMERCE' ?
                                                <>
                                                    { kpiold.HORA23 < kpi.HORA23 ?
                                                    <>  
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 23 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={greenTop}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA23)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA23)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {/* <h3 style={blue}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={blue}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    { this.state.hour === 23 ? //TEST HORA ANTERIOR
                                                        <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA23)}</h3>
                                                        </>
                                                        :
                                                        <>
                                                        <h3 style={blue}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={blue}>{formatter.format(kpiold.HORA23)}</h3>
                                                        </>
                                                    }
                                                    </>
                                                    }
                                                </>
                                                :
                                                <>
                                                { kpiold.HORA23 < kpi.HORA23  ?
                                                    <>  
                                                    { this.state.hour === 23 ? //TEST HORA ANTERIOR
                                                    <>
                                                    <h3 style={greenTop}>{formatter.format(kpi.HORA23)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                    <h3 style={green}>{formatter.format(kpi.HORA23)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={green}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={redOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                :
                                                    <>
                                                    { this.state.hour === 23 ? //TEST HORA ANTERIOR
                                                    <>
                                                        <h3 style={redTop}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={red}>{formatter.format(kpi.HORA23)}</h3>
                                                        <h3 style={greenOld}>{formatter.format(kpiold.HORA23)}</h3>
                                                    </>
                                                    }
                                                    {/* <h3 style={red}>{formatter.format(kpi.HORA13)}</h3>
                                                    <h3 style={greenOld}>{formatter.format(kpiold.HORA13)}</h3> */}
                                                    </>
                                                }
                                                </>
                                                }
                                            </>
                                            }
                                            </>
                                        }
                                    </div>
                                }
                                </>
                                :
                                <>
                                </>
                                }
                            </>
                            })}
                            </td>
                        </tr>
                        })
                    }
                </tbody>
            </table>
   
            {this.state.isLoading === false ?
             <LoadingDots/>               
            :
            <div>
            {/* <Button variant="secondary" className='butn' onClick={() => this.handleSalesYear()}>Update DB</Button>
                <Button variant="info" className='butn' onClick={() => this.handleSales()}>READ FROM CMZ4</Button>
                <Button variant="primary" className='butn' onClick={() => this.handledb()}>READ FROM MONGO</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handleOldSales()}>READ OLD MONGO</Button> */}
                {/* <Button variant="secondary" className='butn' onClick={() => this.handleSalesYear()}>Update DB</Button> */}
                {/* <Button variant="secondary" className='butn' onClick={() => this.sendEmail()}>SendEmail</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handlesftpComplete()}>SFTP</Button> */}
            </div>
            }
        </header>
        </div>
        )
    }
}

export default withAuth(Player);